import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component'
// import Breadcrumb from '../common/breadcrumb';
import CountUp from 'react-countup';
import { toast } from 'react-toastify';
import API from '../../services/API';
import { useNavigate } from 'react-router-dom';


const Homepage = props => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        // total_amount: 0,
        // average_amount: 0,
        totalStudent: 0
    })
    const [attendanceRate, setAttendanceRate] = useState({});
    const [spending, setSpending] = useState(0);
    const [classes, setClass] = useState([]);
    const [columns] = useState([
        {
            name: "Class Name",
            selector: (row) => row.class_name,
            sortable: true,
            center: true,
        },
        {
            name: "Total Students",
            selector: (row) => row.total_students, // Adjust field name to match your backend data
            sortable: true,
            center: true,
        },
        {
            name: "Checked In",
            selector: (row) => row.total_checked_in, // Adjust field name to match your backend data
            sortable: true,
            center: true,
        },
        {
            name: "Total MC",
            selector: (row) => row.total_mc,
            sortable: true,
            center: true,
        },
        {
            name: "Attendance Rate (%)",
            selector: (row) => row.attendance_rate?.toFixed(2), // Ensure 2 decimal points
            sortable: true,
            center: true,
        },
    ]);


    useEffect(() => {
        // const first_time_login = localStorage.getItem('first_time_login')
        // if (first_time_login==1) {
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.get('/portal/totalStudent', { headers }).then(response => {
            if (response.data.response_code == 1000) {
                setState(response.data.data)
                //history.push(`/dashboard`);
            } else if (response.data.response_code == 2000) {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
        API.get('/portal/totalStudentClass', { headers }).then(response => {
            if (response.data.response_code == 1000) {
                setClass(response.data.data)
                //history.push(`/dashboard`);
            } else if (response.data.response_code == 2000) {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
        API.get('/portal/attendanceRateToday', { headers }).then(response => {
            if (response.data.response_code == 1000) {
                setAttendanceRate(response.data.data)
                //history.push(`/dashboard`);
            } else if (response.data.response_code == 2000) {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
        API.get('/portal/getTodaysSpending', { headers }).then(response => {
            if (response.data.response_code == 1000) {
                setSpending(response.data.data.total_spending)
                //history.push(`/dashboard`);
            } else if (response.data.response_code == 2000) {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
        // } else {
        //     navigate(`/first`)
        // }
    }, []);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-100">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12">
                                <DashboardCard
                                    mainComponent={
                                        <>
                                            <span>
                                                &nbsp;
                                                <CountUp className="counter" end={state.totalStudent} separator="," />
                                            </span>
                                            &nbsp;
                                        </>}
                                    subComponent={
                                        <span className='text-red font-weight-bold'>
                                            Total Registered Students
                                        </span>
                                    }
                                />
                            </div>
                            <div className="col-xl-4 col-lg-12">
                                <DashboardCard
                                    mainComponent={
                                        <div className=''>
                                            <CountUp
                                                className="counter"
                                                end={attendanceRate.attendance_rate}
                                                decimals={2}
                                                separator="," /> %
                                        </div>}
                                    subComponent={
                                        <span className='text-red font-weight-bold'>
                                            Attendance Rate
                                        </span>
                                    }
                                />
                            </div>
                            {/* <div className="col-xl-4 col-lg-12">
                                <DashboardCard
                                    mainComponent={
                                        <div className=''>
                                            <CountUp
                                                className="counter"
                                                end={spending}
                                                decimals={2}
                                                separator=","
                                                prefix="RM "
                                            />

                                        </div>}
                                    subComponent={
                                        <span className='text-red font-weight-bold'>
                                            Total Spendings
                                        </span>
                                    }
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25" data-intro="This is Our Topper List">
                            <div className="card-header rounded-25 text-red">
                                <h5>Attendance Breakdown by Class</h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={classes}
                                        striped={true}
                                        center={true}
                                        persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Homepage;

const DashboardCard = (props) => {
    //null,"dark"
    const [cardClassName] = useState(props.theme || "");
    return (
        <div className={"card rounded-25 text-center " + cardClassName}>
            <div className="card-body">
                <div className="">
                    <div className="mb-0 mainComponent">
                        {props.mainComponent}
                    </div>
                    <div className='subComponent pt-3'>
                        {props.subComponent}
                    </div>
                </div>
            </div>
        </div>
    )
}