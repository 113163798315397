import React, { useEffect, useState } from 'react';
import { Password } from '../constant';
import { useNavigate } from 'react-router-dom';
import API from '../services/API';
// import { routes } from '../routes/layouts-routes';
import { toastify } from '../components/toastify'

const LoginPage = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        email:"",
        password:""
    });
  const getToken = async () => {
    const jwt_token = await localStorage.getItem('token');
    var headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': jwt_token
    }
    API.get('/schoolStaff/checkToken', { headers }).then(response => {
      if (response.data.response_code == 1000) {
        navigate(`/dashboard`);
        //history.push(`/dashboard`);
      }
    })
  }
    useEffect(()=>{
        getToken()
    },[])
    const handleSubmit = async () => {
        API.post('/schoolStaff/login',
            {
                email: state.email,
                password: state.password
            }
        ).then(async response => {
            if (response.data.response_code == 1000) {
                localStorage.setItem('token',response.data.data.token)
                localStorage.setItem('full_name',response.data.data.full_name)
                localStorage.setItem('role',response.data.data.role)
                navigate(`/dashboard`);
                // }
            }else if (response.data.response_code == 2000){
                toastify.error(`${response.data.message}`, { autoClose: 5000 })
            } else {
                toastify.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
    }

    const onChange = (field, event) => {
        let fieldValue = event.target.value
        setState(state => ({
            ...state,
            [field]: fieldValue
        }))
    }

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12 bg-white">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                          
                                            <img src={"https://imgur.com/kEzgWVd.png"} alt="" height={150}/>
                                            <h2>Jaringan Keselamatan Anak Anda</h2>
                                        </div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>Login School Portal</h4>
                                                    <h6>{"Enter your Username and Password"} </h6>
                                                </div>
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Email</label>
                                                        <input className="form-control" type="text" value={state.email} onChange={(e)=>onChange('email',e)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{Password}</label>
                                                        <input className="form-control" type="password" value={state.password} onChange={(e)=>onChange('password',e)} onKeyDown={handleKeyDown} />
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn"style={{backgroundColor:"#E94057",color:'white',fontWeight:'bold'}} type="button" onClick={()=>handleSubmit()} >{'LOGIN'}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default LoginPage;