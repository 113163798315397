import React from 'react';

// Import custom Components 
import Homepage from '../components/dashboard/home';
import ClassesPage from '../components/dashboard/Classes';
import PortalUser from '../components/dashboard/PortalUser';
import EmployeeUser from '../components/dashboard/EmployeeUser';
import Settings from '../components/dashboard/Settings';
import ConsolidateFile from '../components/dashboard/Consolidate';
import EmployeeProfile from '../components/dashboard/EmployeeProfile';
import Notification from '../components/dashboard/Notifications';
import TeacherPage from '../components/dashboard/Teachers';
import MerchantPage from '../components/dashboard/Merchant';
import StudentPage from '../components/dashboard/Student';
import ClassProfile from '../components/dashboard/ViewClass';
import GatePage from '../components/dashboard/Gate';

export const routes = [
    { path: `/dashboard`, Component: <Homepage /> },
    { path: `/classes`, Component: <ClassesPage/>},
    { path: `/gate`, Component: <GatePage/>},
    { path: `/class-details/:id`,Component: <ClassProfile/>},
    { path: `/student`, Component: <StudentPage/>},
    { path: `/merchant`, Component: <MerchantPage/>},
    { path: `/teacher`, Component: <TeacherPage/>},
    { path: `/portalUser`, Component: <PortalUser /> },
    { path: `/employee`, Component: <EmployeeUser /> },
    { path: `/settings`, Component: <Settings /> },
    { path: `/notification`, Component: <Notification /> },
    { path: `/transactions`, Component: <ConsolidateFile /> },
    { path: `/profile/:id`, Component: <EmployeeProfile /> },
]
