import React, { useState, Fragment, useEffect } from 'react';
import API from '../../services/API';
// import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
const Settings = props => {

    const [oldPassword,setOldPassword]=useState();
    const [newPassword, setNewPassword] = useState();



    const handleNewPasswordSubmit = async () => {

        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+=[\]{};':"\\|,.<>/?]{8,}$/;

        if (newPassword.length < 8) {
            toast.error("Password is too short", { autoClose: 2000 })
            return;
        }
        if (!passwordPattern.test(newPassword)) {
            toast.error("Password should contain at least one uppercase letter, one lowercase letter and one digit", { autoClose: 2000 })
            return;
        }
        var headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token')
        }
        API.post('/schoolStaff/updatePassword',
            {
                "current_password": oldPassword,
                "new_password": newPassword
            },
            { headers }
        ).then(async response => {
            if (response.data.response_code == 1000) {
                toast.success(`${response.data.message}`, { autoClose: 5000 })
            } else {
                toast.error(`${response.data.message}`, { autoClose: 5000 })
            }
        })
            .catch(error => {
                if (error.response) {
                    toast.error(`Error ${error.name}: ${error.code} ${error.message}`, { autoClose: 5000 })
                } else if (error.request) {
                    toast.error('No response received from server', { autoClose: 5000 })
                } else {
                    toast.error(`Error: ${error.message}`, { autoClose: 5000 })
                }
            })
    }

    return (
        <Fragment>
            {/*<Breadcrumb parent="Dashboard" title="Settings" />*/}
            <div className="container-fluid">
                <div className="row">

                    <div className='card'>
                        <div className='card-header'>
                            <h4 htmlFor="exampleFormControlInput1">Password</h4>
                        </div>
                        <form className="form theme-form">
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword">Current Password</label>
                                            <input onChange={(e) => setOldPassword(e.target.value)} className="form-control" id="oldPassword" type="password" placeholder="Old Password" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword2">New Password</label>
                                            <input onChange={(e) => setNewPassword(e.target.value)} className="form-control" id="newPassword" type="password" placeholder="New Password" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <text id="too-short" className='text'>- At least 8 characters</text>
                                    <text id="no-uppercase" className='text'>- At least one uppercase letter</text>
                                    <text id="no-lowercase" className='text'>- At least one lowercase letter</text>
                                    <text id="no-digits" className='text'>- At least one digit</text>
                                </div>
                                <div className="card-footer text-end">
                                    <button className="btn btn-primary me-2" onClick={() => handleNewPasswordSubmit()} type="button">Update</button>
                                    {/* <input className="btn btn-light" type="reset" defaultValue="Cancel" /> */}
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Settings;