import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import API from '../../services/API';

const TeacherPage = () => {
  const [teachers, setTeachers] = useState([]);
  const [modalAddTeacher, setModalAddTeacher] = useState(false);
  const [newTeacher, setNewTeacher] = useState({
    name: '',
    username: '',
  });

  const toggleAddTeacherModal = () => setModalAddTeacher(!modalAddTeacher);

  const fetchTeachers = () => {
    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };
    API.get('/portal/getTeachers', { headers })
      .then((response) => {
        if (response.data.response_code === 1000) {
          setTeachers(response.data.data);
        } else {
          toast.error(response.data.message, { autoClose: 5000 });
        }
      })
      .catch(() => {
        toast.error('Error fetching teachers', { autoClose: 5000 });
      });
  };

  const handleDeleteTeacher = (username) => {
    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };

    API.delete('/portal/deleteTeacher', {
      headers,
      data: { username },
    })
      .then((response) => {
        if (response.data.response_code === 1000) {
          toast.success('Teacher deleted successfully', { autoClose: 5000 });
          fetchTeachers(); // Refresh the teacher list
        } else {
          toast.error(response.data.message, { autoClose: 5000 });
        }
      })
      .catch(() => {
        toast.error('Error deleting teacher', { autoClose: 5000 });
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTeacher((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddTeacher = () => {
    const { name, username } = newTeacher;
    if (!name || !username) {
      toast.error('All fields are required', { autoClose: 5000 });
      return;
    }

    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };

    API.post('/portal/addTeacher', newTeacher, { headers })
      .then((response) => {
        if (response.data.response_code === 1000) {
          toast.success('Teacher added successfully', { autoClose: 5000 });
          toggleAddTeacherModal();
          fetchTeachers(); // Refresh the teacher list
        } else {
          toast.error(response.data.message, { autoClose: 5000 });
        }
      })
      .catch(() => {
        toast.error('Error adding teacher', { autoClose: 5000 });
      });
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  const columns = [
    {
      name: 'UUID',
      selector: (row) => row.teacher_uuid,
      sortable: true,
      center: true,
    },
    {
      name: 'Phone No',
      selector: (row) => row.phone_no,
      sortable: true,
      center: true,
    },
    {
      name: 'Teacher Name',
      selector: (row) => row.full_name,
      sortable: true,
      center: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <Button
            color="danger"
            onClick={() => handleDeleteTeacher(row.phone_no)}
          >
            Delete
          </Button>
        </div>
      ),
      center: true,
    },
  ];

  return (
    <Fragment>
      <ToastContainer theme="dark" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 xl-100">
            <div className="card">
              <div className="card-header">
                <Button
                  onClick={toggleAddTeacherModal}
                  color="primary"
                  className="ml-2"
                >
                  Add New Teacher
                </Button>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={teachers}
                    striped
                    center
                    persistTableHead
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Teacher Modal */}
      <Modal isOpen={modalAddTeacher} toggle={toggleAddTeacherModal}>
        <ModalHeader toggle={toggleAddTeacherModal}>Add New Teacher</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter Full Name"
                value={newTeacher.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="text"
                name="username"
                className="form-control"
                placeholder="Enter Phone Number"
                value={newTeacher.username}
                onChange={handleInputChange}
                required
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleAddTeacher}>
            Add Teacher
          </Button>
          <Button color="danger" onClick={toggleAddTeacherModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default TeacherPage;
