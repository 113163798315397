import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import API from '../../services/API';

const GatePage = () => {
  const [gates, setGates] = useState([]);
  const [modalAddGate, setModalAddGate] = useState(false);
  const [newGate, setNewGate] = useState({
    gate_name: '',
    description: ''
  });

  // Toggle Add Gate Modal
  const toggleAddGateModal = () => setModalAddGate(!modalAddGate);

  // Fetch Gates
  const fetchGates = () => {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };
    API.get('/attendance/gateList', { headers })
      .then((response) => {
        if (response.data.response_code === 1000) {
          setGates(response.data.data);
        } else {
          toast.error(response.data.message, { autoClose: 5000 });
        }
      })
      .catch(() => {
        toast.error('Error fetching gates', { autoClose: 5000 });
      });
  };

  // Delete Gate
  const handleDeleteGate = (id) => {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };

    API.delete(`/attendance/gate/${id}`, { headers })
      .then((response) => {
        if (response.data.response_code === 1000) {
          toast.success('Gate deleted successfully', { autoClose: 5000 });
          fetchGates(); // Refresh the gate list
        } else {
          toast.success(response.data.message, { autoClose: 5000 });
          fetchGates();
        }
      })
      .catch(() => {
        toast.error('Error deleting gate', { autoClose: 5000 });
      });
  };

  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Add New Gate
  const handleAddGate = () => {
    const { gate_name,description } = newGate;

    if (!gate_name) {
      toast.error('Gate name and school ID are required', { autoClose: 5000 });
      return;
    }

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: localStorage.getItem('token'),
    };

    API.post('/attendance/gateList', newGate, { headers })
      .then((response) => {
        if (response.data.response_code === 1000) {
          toast.success('Gate added successfully', { autoClose: 5000 });
          toggleAddGateModal();
          fetchGates(); // Refresh the gate list
        } else {
          toast.success(response.data.message, { autoClose: 5000 });
          toggleAddGateModal();
          fetchGates(); // Refresh the gate list
        }
      })
      .catch(() => {
        toast.error('Error adding gate', { autoClose: 5000 });
      });
  };

  // Fetch gates on component mount
  useEffect(() => {
    fetchGates();
  }, []);

  // Define Table Columns
  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
      center: true,
    },
    {
      name: 'Gate Name',
      selector: (row) => row.gate_name,
      sortable: true,
      center: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description || 'N/A',
      sortable: true,
      center: true,
    },
    {
      name: 'Created On',
      selector: (row) =>
        row.created_at
          ? moment.unix(row.created_at).format('DD-MMM-YYYY')
          : 'N/A',
      sortable: true,
      center: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <Button
          color="danger"
          onClick={() => handleDeleteGate(row.id)}
          style={{
            fontSize: '12px',
            padding: '5px 10px',
            borderRadius: '4px',
            border: 'none',
          }}
        >
          Delete
        </Button>
      ),
      center: true,
    },
  ];

  return (
    <Fragment>
      <ToastContainer theme="dark" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 xl-100">
            <div className="card">
              <div className="card-header">
                <Button
                  onClick={toggleAddGateModal}
                  color="primary"
                  style={{
                    fontSize: '14px',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    border: 'none',
                  }}
                >
                  Add New Gate
                </Button>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={gates}
                    striped
                    persistTableHead
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Gate Modal */}
      <Modal isOpen={modalAddGate} toggle={toggleAddGateModal}>
        <ModalHeader toggle={toggleAddGateModal}>Add New Gate</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label>Gate Name</label>
              <input
                type="text"
                name="gate_name"
                className="form-control"
                placeholder="Enter Gate Name"
                value={newGate.gate_name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                name="description"
                className="form-control"
                placeholder="Enter Description"
                value={newGate.description}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleAddGate}>
            Add Gate
          </Button>
          <Button color="danger" onClick={toggleAddGateModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default GatePage;
