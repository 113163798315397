import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import API from '../../services/API';

const StudentPage = () => {
    const [students, setStudents] = useState([]); // List of all students
    const [totalRows, setTotalRows] = useState(0); // Total number of students
    const [perPage, setPerPage] = useState(10); // Records per page
    const [currentPage, setCurrentPage] = useState(1); // Current page number

    const [columns] = useState([
        {
            name: "First Name",
            selector: (row) => row.first_name,
            sortable: true,
            center: true,
        },
        {
            name: "Student Balance (RM)",
            selector: (row) => row.student_balance,
            sortable: true,
            center: true,
        },
        {
            name: "Class Name",
            selector: (row) => row.class_name || "Unassigned",
            center: true,
        },
        {
            name: "Parent Contact",
            selector: (row) => row.parent_contact || "N/A",
            center: true,
        },
        {
            name: "Status",
            selector: (row) => (
                <span className={`badge ${row.status === 2 ? 'bg-success' : 'bg-danger'}`}>
                    {row.status === 2 ? 'Active' : row.status === 10 ? 'Inactive' : 'N/A'}
                </span>
            ),
            center: true,
        },
    ]);

    // Fetch students from the API
    const fetchStudents = (page = 1, limit = 10) => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.get(`/portal/getStudents?page=${page}&limit=${limit}`, { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    setStudents(response.data.data.students);
                    setTotalRows(response.data.data.total);
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error fetching students", { autoClose: 5000 });
            });
    };

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchStudents(page, perPage);
    };

    // Handle rows per page change
    const handlePerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchStudents(page, newPerPage);
    };

    useEffect(() => {
        fetchStudents(currentPage, perPage);
    }, []);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25 text-red">
                                <h5>Students</h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={students}
                                        striped={true}
                                        persistTableHead
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[10, 20, 50]}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerPageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default StudentPage;
