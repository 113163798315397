import {
    Home,
    Users,
    Star,
    ShoppingBag,
    Map,
    Monitor,
    Settings,
    MapPin
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Dashboard', icon: Home, type: 'link',path:'/dashboard', badgeType: 'primary', active: false
    },
    {
        title: 'Classes', icon: Map, type: 'link', path:'/classes',active: false
    },
    {
        title: 'Merchant', icon: ShoppingBag, type: 'link', path:'/merchant',active: false
    },
    {
        title: 'Teacher', icon: Monitor, type: 'link', path:'/teacher',active: false
    },
    {
        title: 'Gates', icon: MapPin, type: 'link', path:'/gate',active: false
    },
    {
        title: 'Student', icon: Star, type: 'sub', active: false, children: [
            { path: '/student', type: 'link', title: 'Students' },
            // { path: '/employee', type: 'link', title: 'Employee User' }
        ]
    },
    {
        title: 'Users', icon: Users, type: 'sub', active: false, children: [
            { path: '/portalUser', type: 'link', title: 'Portal User' }
        ]
    },
    {
        title: 'Settings', icon: Settings, type: 'link', path:'/settings',active: false
    },
]
