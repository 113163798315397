import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import API from '../../services/API';

const MerchantPage = () => {
    const [merchants, setMerchants] = useState([]); // List of all merchants
    const [columns] = useState([
        {
            name: "Merchant Name",
            selector: (row) => row.merchant_name,
            sortable: true,
            center: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            center: true,
        },
        {
            name: "Contact Number",
            selector: (row) => row.phone_number,
            center: true,
        },
        {
            name: "Status",
            selector: (row) => (
                <span className={`badge ${row.status == '1' ? 'bg-success' : 'bg-danger'}`}>
                    {row.status == '1' ? 'Active' : 'Inactive'}
                </span>
            ),
            center: true,
        },
    ]);

    // Fetch merchants from the API
    const fetchMerchants = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };

        API.get('/portal/listMerchants', { headers })
            .then((response) => {
                if (response.data.response_code === 1000) {
                    setMerchants(response.data.data);
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 });
                }
            })
            .catch(() => {
                toast.error("Error fetching merchants", { autoClose: 5000 });
            });
    };

    useEffect(() => {
        fetchMerchants();
    }, []);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25 text-red">
                                <h5>Merchants</h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={merchants}
                                        striped={true}
                                        center={true}
                                        persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MerchantPage;
